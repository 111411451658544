import styled from "styled-components";

import { Typography } from "../typography";
import { DividerMark } from "../dividerMark";

const Container = styled.div(() => ({
  display: "flex",
  flexGrow: 1,
  height: 740,
  boxSizing: "border-box",
  marginTop: -3,
  "@media only screen and (max-width: 900px)": {
    flexDirection: "column",
    height: "auto",
    minHeight: 1000,
  },
}));

const ContainerInternal = styled.div(({ showPreview }) => ({
  display: "flex",
  boxSizing: "border-box",
  marginTop: -3,
  padding: "24px 20px",
  flexGrow: +showPreview,
  "@media only screen and (max-width: 900px)": {
    flexDirection: "column-reverse",
  },
}));

const Header = styled.div(() => ({
  width: 40,
  paddingLeft: 20,
  display: "flex",
  flexDirection: "column",
  "@media only screen and (max-width: 900px)": {
    width: "100%",
    flexDirection: "row-reverse",
    padding: "16px 0",
    justifyContent: "space-between",
  },
  justifyContent: "flex-start",
}));

const Body = styled.div(() => ({
  display: "flex",
  flexGrow: 1,
  "@media only screen and (max-width: 900px)": {
    flexDirection: "column",
  },
}));

const Image = styled.div(({ preview }) => ({
  backgroundImage: `url(${preview})`,
  flexGrow: 1,
  "@media only screen and (max-width: 900px)": {
    height: 180,
    marginBottom: 16,
  },
}));

const Plus = ({ onClick }) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <line x1="12.6665" y1="7" x2="12.6665" y2="17" stroke="white" />
    <line x1="17.6666" y1="12" x2="7.66663" y2="12" stroke="white" />
    <circle cx="12.6666" cy="12" r="11.5" stroke="white" />
  </svg>
);

const Minus = ({ onClick }) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <line x1="16.7998" y1="12.5" x2="7.19981" y2="12.5" stroke="white" />
    <circle cx="12" cy="12" r="11.5" stroke="white" />
  </svg>
);

const IconWrapper = styled.div(() => ({
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
}));

const SectionText = styled(Typography)(() => ({
  "@media only screen and (min-width: 900px)": {
    display: "flex",
    justifyContent: "flex-end",
    transform: "rotate(-90deg)",
    marginTop: 24,
  },

  marginTop: 0,
  marginBottom: 5,
}));

const Text = styled.div(() => ({
  paddingLeft: 40,
  maxWidth: 644,
  "@media only screen and (max-width: 900px)": {
    paddingLeft: 0,
  },
}));

const SubText = styled.div(() => ({
  display: "flex",
  "@media only screen and (max-width: 900px)": {
    marginTop: 24,
  },
}));

const Info = styled.div(() => ({}));

const Mark = styled.div(() => ({
  marginTop: 15,
  "@media only screen and (max-width: 900px)": {
    display: "none",
  },
}));

const Section = ({
  title,
  preview,
  showPreview,
  openSection,
  showContent,
  text,
}) => {
  return (
    <ContainerInternal showPreview={showPreview} id={title.toLowerCase()}>
      {showPreview && (
        <Body>
          <Image preview={preview} />
          {showContent && (
            <Text>
              <Typography size="40" line="56" color="blue">
                {text[0]}
              </Typography>
              <SubText>
                <Mark>
                  <DividerMark />
                </Mark>
                <Info>
                  <Typography size="18" line="27" color="white">
                    {text[1]}
                  </Typography>
                  <br />
                  <Typography size="18" line="27" color="white">
                    {text[2]}
                  </Typography>
                </Info>
              </SubText>
            </Text>
          )}
        </Body>
      )}

      <Header>
        <IconWrapper>
          {!showContent ? (
            <Plus
              onClick={() => {
                openSection(title);
              }}
            />
          ) : (
            <Minus onClick={() => openSection(null)} />
          )}
        </IconWrapper>
        <SectionText size="24">{title}</SectionText>
      </Header>
    </ContainerInternal>
  );
};

const SectionDividerLine = styled.div(({ theme }) => ({
  opacity: 0.5,
  width: 1,
  background: theme.palette.blue,

  "@media only screen and (max-width: 900px)": {
    height: 1,
    width: "100%",
  },
}));

export const Services = ({ servicesData, openSection }) => {
  return (
    <Container>
      {servicesData.map(({ title, preview, open, text }, index) => (
        <>
          <Section
            title={title}
            preview={preview}
            text={text}
            showPreview={open || servicesData.every((e) => !e.open)}
            showContent={open}
            openSection={openSection}
          />
          {index !== servicesData.length - 1 && <SectionDividerLine />}
        </>
      ))}
    </Container>
  );
};
