import { useState } from "react";
import styled from "styled-components";

import { Typography } from "../typography";
import Logo from "./logo.gif";
import { ReactComponent as Burger } from "./burger.svg";
import { ReactComponent as Close } from "./close.svg";
import { Button } from "../button";
import { CONFIG } from "../../CONFIG";

const Container = styled.div(({ theme, showDivider }) => ({
  position: "fixed",
  width: "100vw",
  zIndex: 100,
  display: "flex",
  boxSizing: "border-box",
  height: 89,
  background: theme.palette.darkestBlue,
  boxShadow: showDivider && `0px 1px 0px 0px ${theme.palette.blue}`,
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 40px",
}));

const Link = styled.div(({ theme }) => ({
  background: `linear-gradient(0deg, ${theme.palette.blueLink}, ${theme.palette.blueLink}) no-repeat right bottom / 0 2px`,
  transition: "background-size 0.4s",
  "&:hover, &:focus": {
    backgroundSize: "100% 2px",
    backgroundPositionX: "left",
  },
}));

const Nav = styled.div(() => ({
  display: "flex",
  alignItems: "center",

  "@media only screen and (max-width: 900px)": {
    display: "none",
  },
  "& > *": {
    height: 21,
    marginLeft: 40,
    cursor: "pointer",
  },
}));

const Icon = styled.div(() => ({
  cursor: "pointer",
  zIndex: 120,
}));

const NavMobileToggle = styled.div(() => ({
  "@media only screen and (min-width: 901px)": {
    display: "none",
  },
  zIndex: 120,
}));

const NavMobile = styled.div(({ theme }) => ({
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: 453,
  background: theme.palette.basicallyBlack,
  zIndex: 110,
}));

const MobileLinks = styled.div(() => ({
  position: "absolute",
  width: "calc(100% - 32px)",
  top: 89,
  padding: "0 16px 16px 16px",
}));

const MobileLink = styled.div(({ theme }) => ({
  width: "100%",
  padding: "16px 0",
  borderBottom: `1px solid ${theme.palette.darkestBlue}`,
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
}));

const ContactUs = () => (
  <Button
    onClick={() => {
      window.open(
        `mailto:${CONFIG.CONTACT_US.EMAIL}?subject=${CONFIG.CONTACT_US.SUBJECT}&body=${CONFIG.CONTACT_US.BODY}`
      );
    }}
  >
    Contact
  </Button>
);

const links = [
  {
    title: "Pedigree",
  },
  {
    title: "Advisory",
  },
  {
    title: "Engineering",
  },
  {
    title: "Government",
  },
  {
    title: "Resources",
  },
];

export const Header = ({ showDivider, ...props }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Container showDivider={showDivider}>
      <Icon
        onClick={() => {
          window.open(CONFIG.LOGO_URL, "_blank");
        }}
      >
        <img width="63" height="56" src={Logo} alt="loading..." />
      </Icon>
      {isOpened && (
        <NavMobile>
          <MobileLinks>
            {links.map(({ title, url }) => (
              <MobileLink
                key={title}
                onClick={() => {
                  props[`on${title}`]();
                  setIsOpened(false);
                }}
              >
                <Typography size="24" color="blue">
                  {title}
                </Typography>
              </MobileLink>
            ))}
            <MobileLink>
              <ContactUs />
            </MobileLink>
          </MobileLinks>
        </NavMobile>
      )}
      <NavMobileToggle>
        <Icon onClick={() => setIsOpened((v) => !v)}>
          {isOpened ? <Close /> : <Burger />}
        </Icon>
      </NavMobileToggle>
      <Nav>
        {links.map(({ title, url }) => (
          <Link key={title} onClick={props[`on${title}`]}>
            <Typography size="16">{title}</Typography>
          </Link>
        ))}
        <ContactUs />
      </Nav>
    </Container>
  );
};
