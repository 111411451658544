import styled from "styled-components";

export const Typography = styled.div(
  ({ theme, color = "white", size = 18, line }) => ({
    display: "flex",
    alignItems: "center",
    color: theme.palette[color],
    fontSize: `${size}px`,
    lineHeight: `${line || size}px`,
  })
);
