import styled from "styled-components";
import { Typography } from "../typography";
import { GhostButton } from "../button";
import { useState } from "react";
import { Modal } from "../modal";

export const SectionDividerLine = styled.div(({ theme }) => ({
  opacity: 0.5,
  height: 1,
  background: theme.palette.blue,
  margin: "0px 32px",
}));

const Box = styled.div(() => ({
  padding: 40,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "wrap",
}));

const Margin = styled.div(() => ({
  marginTop: 8,
  marginBottom: 8,
}));

const StyledTypography = styled(Typography)(() => ({
  maxWidth: 984,
}));

const Player = styled.iframe(() => ({
  width: "100%",
  aspectRatio: "16 / 9",
}));

export const Resource = ({ title, link }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Modal title={title} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Player
          src={link}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        />
      </Modal>
      <Box>
        <Margin>
          <StyledTypography size="48" color="blue">
            {title}
          </StyledTypography>
        </Margin>
        <Margin>
          <GhostButton onClick={() => setIsOpen(true)}>PLAY VIDEO</GhostButton>
        </Margin>
      </Box>
      <SectionDividerLine />
    </>
  );
};
