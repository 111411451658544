export const CONFIG = {
  LOGO_URL: "https://iqumulus.com/",
  TWITTER_URL: "https://twitter.com/iqumulus",
  LINKEDIN_URL: "https://linkedin.com/company/iqumulus",
  MEDIUM_URL: "https://medium.com/iqumulus",
  CONTACT_US: {
    EMAIL: "info@iqumulus.com",
    SUBJECT: "LANDING CONTACT",
    BODY: "Thanks for your inquiry.",
  },
  OUR_SERVICES: [
    {
      TITLE: "C-Suite Webinar with Corvalent",
      LINK: "https://www.youtube.com/embed/68ZVAELGuJA?si=vYmLClMUWuvQ4-Y1",
    },
    {
      TITLE: "An Introduction to IQ",
      LINK: "https://www.youtube.com/embed/8vcr1SCH2K8?si=borCcM6-5_6FuRw7",
    },
    {
      TITLE: "Differentiation",
      LINK: "https://www.youtube.com/embed/c2ViyJI9teE?si=OjMzIX1eRwpDV3z9",
    },
    {
      TITLE: "Specialization",
      LINK: "https://www.youtube.com/embed/6nTyJ7l8vIw?si=7y3Fvn44lBdSSSX7",
    },
    {
      TITLE: "The Modern Transformation Paradigm©",
      LINK: "https://www.youtube.com/embed/no585SQniag?si=dJi3KVIdmmPLs8EC",
    },
    {
      TITLE: "What is AI?",
      LINK: "https://www.youtube.com/embed/4CNti3-M3Ls?si=eT5_m_aLHkVhAmVd",
    },
    {
      TITLE: "Non-linear Problems, Machine Learning, and Deep Learning for CEOs",
      LINK: "https://www.youtube.com/embed/Sz8m5-aM4iY?si=Qo2pjIP4D-yLvL-d?rel=0",
    },
  ],
};
