import styled from "styled-components";
import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as Linkedin } from "./linkedin.svg";
import { ReactComponent as Medium } from "./medium.svg";
import { Typography } from "../typography";
import { CONFIG } from "../../CONFIG";
import { SectionDividerLine } from "../resource";
import { useState } from "react";
import { Modal } from "../modal";

const Divider = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minHeight: 145,
  padding: "0px 40px",
}));

const Container = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  width: "100%",
  background: theme.palette.basicallyBlack,
}));

const Social = styled.div(() => ({
  display: "flex",
}));

const LogoWrapper = styled(Logo)(() => ({
  cursor: "pointer",
}));

const Logos = styled.div(() => ({
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  marginTop: 20,
}));

const Year = styled.div(({ theme }) => ({
  padding: 40,
  background: theme.palette.basicallyBlack,
  display: "flex",
  justifyContent: "space-between",
}));

const Privacy = styled(Typography)(() => ({
  cursor: "pointer",
}));

const PrivacyContainer = styled.div(() => ({
  height: "60vh",
  overflowY: "scroll",
}));

const Box = styled.div`
  padding-top: 14px;
`;

export const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Container>
        <Divider>
          <LogoWrapper
            onClick={() => {
              window.open(CONFIG.LOGO_URL, "_blank");
            }}
          />
        </Divider>
        <Divider>
          <Social>
            <div>
              <Typography size="18" color="blue">
                Follow us
              </Typography>
              <Logos>
                <Twitter
                  onClick={() => {
                    window.open(CONFIG.TWITTER_URL, "_blank");
                  }}
                />
                <Linkedin
                  onClick={() => {
                    window.open(CONFIG.LINKEDIN_URL, "_blank");
                  }}
                />
                <Medium
                  onClick={() => {
                    window.open(CONFIG.MEDIUM_URL, "_blank");
                  }}
                />
              </Logos>
            </div>
          </Social>
        </Divider>
      </Container>
      <SectionDividerLine />
      <Year>
        <Typography size="14" color="blue">
          Ⓒ{new Date().getFullYear()} IQumulus ® | 9600 Escarpment Blvd, Austin, TX 78749, U.S.
        </Typography>
        <Privacy size="14" color="blue" onClick={() => setIsOpen(true)}>
          Privacy Policy
        </Privacy>
        <Modal
          title="Privacy Policy"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          background="darkestBlue"
        >
          <PrivacyContainer>
            <Typography size="16" color="white">
              Our Privacy & Terms policy sets out how IQumulus (“IQ) uses and
              protects any information that you may provide when using this
              website. At the end it also sets out general terms for the use of
              this website. IQ is committed to ensuring that your privacy is
              protected. Should we ask you to provide certain information by
              which you can be identified when using this website, then you can
              be assured that it will only be used in accordance with this
              privacy statement. IQ may change this policy from time to time by
              updating this page. You should check this page from time to time
              to ensure that you are happy with any changes. This policy is
              effective from January, 2021.
            </Typography>
            <Box />
            <Typography size="18" color="blue">
              Security
            </Typography>
            <Box />
            <Typography size="16" color="white">
              If we collect any of your information, we are committed to
              ensuring that it is secure. In order to prevent unauthorized
              access or disclosure we have put in place suitable physical,
              electronic and managerial procedures to safeguard and secure the
              information we may collect online.
            </Typography>
            <Box />
            <Typography size="18" color="blue">
              Links to other websites
            </Typography>
            <Box />
            <Typography size="16" color="white">
              Our website may contain links to other websites of interest.
              However, once you have used these links to leave our site, you
              should note that we do not have any control over that other
              website. Therefore, we cannot be responsible for the protection
              and privacy of any information that you provide while visiting
              such sites and, such sites are not governed by this privacy
              statement. You should exercise caution and look at the privacy
              statement applicable to the website in question.
            </Typography>
            <Box />
            <Typography size="18" color="blue">
              Personal Information
            </Typography>
            <Box />
            <Typography size="16" color="white">
              We will not sell, distribute or lease your personal information to
              third parties unless we have your permission or are required by
              law. We may use your personal information to send you promotional
              information about third parties, which we think you may find
              interesting if you tell us that you wish this to happen.
            </Typography>
            <Box />
            <Typography size="18" color="blue">
              Terms of Use
            </Typography>
            <Box />
            <Typography size="16" color="white">
              This website, and all of its contents, are published solely for
              self-promotional, business-to-business purposes for the exclusive
              use of IQ clients, prospects, partners, contractors, employees,
              and investors. It is not intended for the casual viewing or
              entertainment purposes of the public. IQ explicitly forbids the
              downloading or re-purposing of any text, audio, video, visual,
              programming, or data, without the explicit prior written consent of IQ.
              Furthermore, by accessing this website you agree not to use this 
              website or any of its contents for judicially noticed pseudolegal actions,
              such as but not limited to any type of pseudolegal intimidation, pseudolegal harassment,
              pseudolegal trolling, pseudolegal "phishing," or any other business customs and corporate
              pseudolegal behaviors known to people of average intelligence as economically
              exploitative and value extractive, and you understand and accept
              that should you engage in such behaviors IQ will pursue swift legal action
              in all applicable jurisdictions; to the full extent of all applicable law;
              based on but not limited to all applicable judicially noticed fact.
            </Typography>
          </PrivacyContainer>
        </Modal>
      </Year>
    </>
  );
};
