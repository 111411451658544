import styled from "styled-components";
import { Typography } from "../typography";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: ${({ theme, background }) =>
    theme.palette[background || "basicallyBlack"]};
  padding: 56px 40px 40px 40px;
  border-radius: 8px;
  position: relative;
  width: 80%; /* Adjust the width of your modal */
  max-width: 800px; /* Set a maximum width if needed */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
`;

const ModalClose = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.white};

  &:hover {
    color: #f00; /* Change color on hover if desired */
  }
`;

const Header = styled(Typography)(() => ({
  marginBottom: 24,
}));

export const Modal = ({ title, isOpen, onClose, children, background }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent
        onClick={(e) => e.stopPropagation()}
        background={background}
      >
        <Header size="48" color="white">
          {title}
        </Header>
        <ModalClose onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M25.7081 24.2931C25.801 24.386 25.8747 24.4963 25.9249 24.6177C25.9752 24.7391 26.0011 24.8692 26.0011 25.0006C26.0011 25.132 25.9752 25.2621 25.9249 25.3835C25.8747 25.5048 25.801 25.6151 25.7081 25.7081C25.6151 25.801 25.5048 25.8747 25.3835 25.9249C25.2621 25.9752 25.132 26.0011 25.0006 26.0011C24.8692 26.0011 24.7391 25.9752 24.6177 25.9249C24.4963 25.8747 24.386 25.801 24.2931 25.7081L16.0006 17.4143L7.70806 25.7081C7.52042 25.8957 7.26592 26.0011 7.00056 26.0011C6.73519 26.0011 6.4807 25.8957 6.29306 25.7081C6.10542 25.5204 6 25.2659 6 25.0006C6 24.7352 6.10542 24.4807 6.29306 24.2931L14.5868 16.0006L6.29306 7.70806C6.10542 7.52042 6 7.26592 6 7.00056C6 6.73519 6.10542 6.4807 6.29306 6.29306C6.4807 6.10542 6.73519 6 7.00056 6C7.26592 6 7.52042 6.10542 7.70806 6.29306L16.0006 14.5868L24.2931 6.29306C24.4807 6.10542 24.7352 6 25.0006 6C25.2659 6 25.5204 6.10542 25.7081 6.29306C25.8957 6.4807 26.0011 6.73519 26.0011 7.00056C26.0011 7.26592 25.8957 7.52042 25.7081 7.70806L17.4143 16.0006L25.7081 24.2931Z"
              fill="white"
            />
          </svg>
        </ModalClose>
        {children}
      </ModalContent>
    </ModalOverlay>
  );
};
