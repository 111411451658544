import styled from "styled-components";
import { Typography } from "../typography";
import { ReactComponent as Arrow } from "./ArrowRight.svg";
import { ReactComponent as Play } from "./Play.svg";

const Container = styled.div(({ theme, background, color }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 168,
  height: 37,
  padding: `18px 8px`,
  cursor: "pointer",
  background: theme.palette[background],
  transitionDuration: "0.4s",
  boxSizing: "border-box",
  border: `1px solid ${theme.palette.blue}`,
  "& > *": {
    transitionDuration: "0.4s",
    color: theme.palette[color] || theme.palette.darkestBlue,
    fill: theme.palette[color] || theme.palette.darkestBlue,
  },

  "&:hover, &:focus": {
    background: theme.palette.blueLink,
    "& > *": {
      transitionDuration: "0.4s",
      color: theme.palette.basicallyBlack,
      fill: theme.palette.basicallyBlack,
    },
  },
}));

const ArrowContainer = styled(Arrow)(() => ({
  marginLeft: 10,
}));

export const Button = ({ children, onClick }) => {
  return (
    <Container background="blue" onClick={onClick}>
      <Typography size="16">{children}</Typography>
      <ArrowContainer />
    </Container>
  );
};

const PlayContainer = styled(Play)(() => ({
  marginRight: 10,
}));

export const GhostButton = ({ children, onClick }) => {
  return (
    <Container onClick={onClick} color="blue">
      <PlayContainer />
      <Typography size="16">{children}</Typography>
    </Container>
  );
};
