import styled from "styled-components";
import { DividerMark } from "../dividerMark";
import { Typography } from "../typography";

const SectionDividerContainer = styled.div(() => ({
  height: 69,
}));

const SectionDividerLine = styled.div(({ theme }) => ({
  opacity: 0.5,
  height: 1,
  background: theme.palette.blue,
}));

const Box = styled.div(() => ({
  padding: "24px 40px",
  display: "flex",
  alignItems: "center",
}));

export const SectionDivider = ({ children }) => {
  return (
    <SectionDividerContainer>
      <SectionDividerLine />
      <Box>
        <DividerMark />
        <Typography size="16" color="blue">
          {children}
        </Typography>
      </Box>
      <SectionDividerLine />
    </SectionDividerContainer>
  );
};
